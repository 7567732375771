import React from "react";
import odooImg from "../assets/images/Odoo-Development-Company-4.svg";

const LookingFor = () => {
  return (
    <section className="odoo-erp-developement-service dev-service-section-spacing">
      <div className="development-service-wrapper bg-purple">
        <div className="container">
          <div className="service-section-top-wrap d-flex justify-content-between font-white">
            <h2 className="service-section-title font-700 text-uppercase">
              ARE YOU LOOKING FOR ODOO ERP DEVELOPMENT SERVICE ?
            </h2>
            <img src={odooImg} alt="Odoo ERP Implementation Company" className="service-section-logo" />
          </div>
          <div className="service-section-bottom-wrap d-md-flex d-block justify-content-between align-items-end">
            <p className="service-section-desc font-white desc-width">
            CodeTrade is the leading Odoo development company in Saudi Arabia. We craft powerful, custom Odoo solutions that transform the way you work. Collaborate with us to optimize your operations and reach your business objectives through our professional Odoo development services. Get a free consultation today!
            </p>
            <a className="btn-contact-us-wrap" href="https://www.codetrade.io/contact-us/" target="_blank" rel="noopener noreferrer">
              <button className="btn btn-contact-us text-uppercase font-600">
                CONTACT US
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LookingFor;