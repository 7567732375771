import React from "react";

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <div className="modal" style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }}>
      {children}
    </div>
  );
};

export default Modal;