import React from "react";
import title from "../assets/images/title.svg";

const Blog = () => {
  return (
    <section className="our-blog-section">
      <div className="container section-spacing">
        <div className="section-title-wrap">
          <h2 className="section-title font-purple font-900 position-relative width-fit mx-auto">
            OUR BLOG
            <img src={title} alt="title" className="title-highlight" />
          </h2>
        </div>
        <div className="our-blog-wrapper">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12 mt-3">
              <div className="blog-cards position-relative">
                <img src={require('../assets/images/Odoo-Development-Company-6.png')} alt="Odoo ERP Implementation Company" className="blog-img w-100" />
                <a href="https://www.linkedin.com/pulse/odoo-erp-solutions-empower-saudi-industries-growth-03hyf/?trackingId=Zx215NLuFS9wZRYZ17gz9A%3D%3D" rel="noopener noreferrer" target="_blank">
                  <h6 className="blog-title font-white text-uppercase font-700">
                    Odoo ERP Solutions: Empower Saudi Industries For Growth
                  </h6>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 mt-3">
              <div className="blog-cards position-relative">
                <img src={require('../assets/images/Odoo-Development-Company-7.png')} alt="Odoo ERP Implementation Company" className="blog-img w-100" />
                <a href="https://www.codetrade.io/blog/the-power-of-ai-integration-in-odoo-17/" rel="noopener noreferrer" target="_blank">
                  <h6 className="blog-title font-white text-uppercase font-700">
                    The Power of AI Integration In Odoo 17
                  </h6>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 mt-3">
              <div className="blog-cards position-relative">
                <img src={require('../assets/images/Odoo-Development-Company-8.png')} alt="Odoo ERP Implementation Company" className="blog-img w-100" />
                <a href="https://www.codetrade.io/blog/business-operations-with-odoo-erp-development/" rel="noopener noreferrer" target="_blank">
                  <h6 className="blog-title font-white text-uppercase font-700">
                    Streamline Your Business Operations with Odoo ERP Development
                  </h6>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;