import React from "react";
import odooImg from '../assets/images/Odoo-Development-Company-4.svg';

const StartProject = () => {
  return (
    <section className="start-project-with-us">
      <div className="start-project-with-us-wrapper bg-purple margin-top position-relative">
        <div className="container">
          <div className="bg-img-label">
            <img src={odooImg} alt="Odoo ERP Implementation Company" className="odoo-back-img" />
          </div>
          <div className="start-project-section-top">
            <h2 className="start-project-section-title font-700 text-uppercase font-white text-center">
              WANT TO START A PROJECT WITH US?
            </h2>
            <p className="start-project-desc font-white text-center">
              CodeTrade is your best partner for Odoo implementations in Saudi Arabia. We deliver comprehensive Odoo ERP solutions within just 4 weeks, customized to meet the unique needs of your business. Get started with us today and experience seamless integration and enhanced efficiency.
            </p>
            <a href="https://www.codetrade.io/contact-us/" target="_blank" rel="noopener noreferrer" className="btn-request-consultation-wrap">
              <button className="btn btn-request-consultation text-uppercase font-600">
                REQUEST CONSULTATION
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartProject;