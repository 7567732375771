import React from "react";
import title from "../assets/images/title.svg";
import done_icon from "../assets/images/done-icon.png";

const AboutUs = () => {
  return(
    <section className="odoo-theme-about-us">
      <div className="container section-spacing">
        <div className="section-title-wrap">
          <h2 className="section-title font-purple font-900 position-relative width-fit mx-auto">
            WANT TO KNOW MORE ABOUT US ?
            <img src={title} alt="title" className="title-highlight" />
          </h2>
        </div>
        <div className="about-us-content-wrapper">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="about-us-img-wrap">
                <img src={require('../assets/images/Odoo-Development-Company-1.png')} alt="Odoo ERP Implementation Company" className="about-us-img w-100"/>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-12">
              <div className="about-us-desc-wrap">
                <h3 className="desc-wrap-title font-black font-700 text-align-center">
                  Leading Odoo ERP Development & Customization in Saudi Arabia
                </h3>
                <p className="desc-wrap-text text-align-center">
                  CodeTrade is one of the premier <a href="https://www.codetrade.io/odoo/" className="font-purple" target="_blank" rel="noopener noreferrer">Odoo Development</a> and Customization companies in Saudi Arabia. We have experienced Odoo developers who make tailor-made Odoo ERP systems catering to specific industries' requirements. With time, our range of Odoo services has been honed to the perfect point. As a leading implementation partner for Odoo, we specialize in Odoo ERP Development, serving multiple businesses within the region of Saudi Arabia.
                </p>
                <ul className="desc-wrap-points-list">
                  <li>
                    <div className="bullet-point-wrap">
                      <div className="bullet-point-bg">
                        <img src={done_icon} alt="done_icon" />
                      </div>
                    </div>
                    <span><b>Expert Odoo Development:</b> Our certified Odoo developers can design and develop custom Odoo modules to meet your business needs.</span>
                  </li>
                  <li>
                    <div className="bullet-point-wrap">
                      <div className="bullet-point-bg">
                        <img src={done_icon} alt="done_icon" />
                      </div>
                    </div>
                    <span><b>Seamless Odoo Integration:</b> We integrate Odoo with your existing business systems to ensure a smooth and efficient workflow.</span>
                  </li>
                  <li>
                    <div className="bullet-point-wrap">
                      <div className="bullet-point-bg">
                        <img src={done_icon} alt="done_icon" />
                      </div>
                    </div>
                    <span><b>Exceptional Odoo Support:</b> We offer ongoing support and maintenance to ensure your Odoo system operates flawlessly.</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
  );
};

export default AboutUs;