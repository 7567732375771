import React from "react";
import title from "../assets/images/title.svg"
import odoo_custom from "../assets/images/Odoo Customization.svg";
import implementation from "../assets/images/implementation.svg";
import integration from "../assets/images/integration.svg";
import supportMaintenance from "../assets/images/supportmaintenance.svg";
import webdevelopment from "../assets/images/webappdevelopmetn.svg";
import odooConsultaion from "../assets/images/coonsultation.svg";

const Services = () => {
  return (
    <section className="our-services-section">
      <div className="container section-spacing-top">
        <div className="section-title-wrap">
          <h2 className="section-title font-purple font-900 position-relative width-fit text-uppercase">
            our services
            <img src={title} alt="title" className="title-highlight" />
          </h2>
        </div>
        <div className="row service-main-fr">
          <div className="col-lg-3 col-12 service-frow-title">
            <div className="bg-pruple-fr-service">
              <span className="purple-service-title">Ready to help you in your projects</span>
            </div>
          </div>
          <div className="col-lg-9 col-12 service-frow-card">
            <div className="purple-right-bgservice"></div>
            <div className="row"> 
              <div className="service-card">
                <div className="service-card-wrap">
                  <div className="service-card-top">
                    <div className="service-card-bg">
                      <img src={odoo_custom} alt="odoo_custom" className="service-icon"/>
                    </div>
                  </div>
                  <h5 className="service-card-title font-purple font-600 my-3">
                    Odoo Customization
                  </h5>
                  <p className="service-card-desc">
                    Solutions tailored to meet your specific business requirements.
                  </p>
                </div>
              </div>
              <div className="service-card">
                <div className="service-card-wrap">
                  <div className="service-card-top">
                    <div className="service-card-bg">
                      <img src={implementation} alt="implementaion" className="service-icon"/>
                    </div>
                  </div>
                  <h5 className="service-card-title font-purple font-600 my-3">
                    Odoo Implementation
                  </h5>
                  <p className="service-card-desc">
                    Seamless implementation of Odoo ERP systems.
                  </p>
                </div>
              </div>
              <div className="service-card">
                <div className="service-card-wrap">
                  <div className="service-card-top">
                    <div className="service-card-bg">
                      <img src={integration} alt="integration" className="service-icon"/>
                    </div>
                  </div>
                  <h5 className="service-card-title font-purple font-600 my-3">
                    Odoo Integration
                  </h5>
                  <p className="service-card-desc">
                    Integrate Odoo with existing systems smoothly.
                  </p>
                </div>
              </div>
              <div className="service-card">
                <div className="service-card-wrap">
                  <div className="service-card-top">
                    <div className="service-card-bg">
                      <img src={supportMaintenance} alt="supportMantainance" className="service-icon"/>
                    </div>
                  </div>
                  <h5 className="service-card-title font-purple font-600 my-3">
                    Odoo Migration
                  </h5>
                  <p className="service-card-desc">
                    Efficient migration from legacy systems to Odoo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-5 mb-5">
          <div className="col-lg-4 col-12">
            CodeTrade stands out as a premier provider of top-notch Odoo Development and Customization services in Saudi Arabia. Our services ensure seamless integration and customization, enhancing operational efficiency and productivity.
            We pride ourselves on delivering tailored solutions that enhance business efficiency and streamline operations, ensuring that our clients achieve their strategic goals with the help of cutting-edge Odoo technology.  Get ready for reliable and innovative Odoo ERP implementations.
          </div>
          <div className="col-lg-8 col-12 service-srow-card">
            <div className="odoo_service_logo"></div>
            <div className="purple-serw-right-bgservice"></div>
            <div className="row">
              <div className="service-card">
                <div className="service-card-wrap">
                  <div className="service-card-top">
                    <div className="service-card-bg">
                      <img src={webdevelopment} alt="webdevelopment" className="service-icon"/>
                    </div>
                  </div>
                  <h5 className="service-card-title font-purple font-600 my-3">
                    Odoo Website Development
                  </h5>
                  <p className="service-card-desc">
                    Create dynamic websites/apps and Modules with Odoo.
                  </p>
                </div>
              </div>
              <div className="service-card">
                <div className="service-card-wrap">
                  <div className="service-card-top">
                    <div className="service-card-bg">
                      <img src={supportMaintenance} alt="supportMaintenance" className="service-icon"/>
                    </div>
                  </div>
                  <h5 className="service-card-title font-purple font-600 my-3">
                    Odoo Support and Maintenance
                  </h5>
                  <p className="service-card-desc">
                    Continuous support for optimal performance.
                  </p>
                </div>
              </div>
              <div className="service-card">
                <div className="service-card-wrap">
                  <div className="service-card-top">
                    <div className="service-card-bg">
                      <img src={odooConsultaion} alt="odooConsultation" className="service-icon"/>
                    </div>
                  </div>
                  <h5 className="service-card-title font-purple font-600 my-3">
                    Odoo Consultation
                  </h5>
                  <p className="service-card-desc">
                    Expert advice to maximize your Odoo investment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;