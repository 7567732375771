import React from 'react';
import logo_white from '../assets/images/logo_white.svg';
import logo from '../assets/images/logo.svg';


const Header = () => {
  return(
    <>
      <b className="screen-overlay"></b>
      <nav className="navbar2 navbar d-lg-none sticky-top class navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand" href="https://www.codetrade.io">
            <img src={logo_white} alt='Odoo ERP Implementation Company'/>
          </a>
          <div className="responsive-menu-pull-right d-none">
            <button data-trigger="#navbar_main" className="navbar-toggler" type="button">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
      </nav>
      <nav id="navbar_main" className="mobile-offcanvas navbar sticky-top class navbar-expand-lg navbar-secondary-light">
        <div id="navbar-top" className="container nav-cont">
          <div id="logo">
            <a className="logo" href="https://www.codetrade.io">
              <img className="fixed-logo" src={logo_white} width="150px" alt="Odoo ERP Implementation Company" height="auto"/>
              <img className="logo-blueblack" src={logo} width="150px" alt="Odoo ERP Implementation Company" height="auto"/>
            </a>
          </div>
          <ul className="navbar-nav d-none">
            <li className="nav-item active">
              <a className="nav-link" href="/#">About Us</a>
            </li>
            <li className="nav-item"><a className="nav-link" href="/#">Services</a></li>
            <li className="nav-item">
              <a className="nav-link" href="/#">Technologies</a>
            </li>
            <li className="nav-item">
              <a className="nav-link mobile-hidden" href="/#">Hire Dedicated Developers</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#">Our Expertise</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#">Source Code</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#">Blog</a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;