import axios from 'axios';

export const getCountryCode = async () => {
  try {
    const response = await axios.get('https://ipapi.co/json/');
    const countryCode = response.data.country.toLowerCase();
    return countryCode;
  } catch (error) {
    console.error("Error fetching country code:", error);
    return 'sa'; // Default fallback
  }
};