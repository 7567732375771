import './assets/css/landing-page.css';
import Header from './components/Header';
import Summary from './components/Summary';
import AboutUs from './components/AboutUs';
import Process from './components/Process';
import Banner from './components/Banner';
import Services from './components/Services';
import WeServe from './components/WeServe';
import LookingFor from './components/LookingFor';
import Procure from './components/Procure';
import ChooseUs from './components/ChooseUs';
import Blog from './components/Blog';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import StartProject from './components/StartProject';
import Modal from './components/Modal';
import { getCountryCode } from './components/getCountryCode';

import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";

import closeButton from "./assets/images/close.svg";
import website from "./assets/images/website.svg";
import emailImage from "./assets/images/mail.svg";
import call from "./assets/images/call.svg";
import nameImage from "./assets/images/NAME.svg";
import emailImg from "./assets/images/email.svg";
import notes from "./assets/images/note.svg";
import realEstateImg from "./assets/images/real estate.svg";
import doneIcon from "./assets/images/done-icon.svg";

import { PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import 'react-international-phone/style.css'

import ReCAPTCHA from 'react-google-recaptcha';
import TagManager from 'react-gtm-module';

const options = [
  { value: "healthcare", label: "HealthCare" },
  { value: "manufacturing", label: "Manufacturing" },
  { value: "realestate", label: "Real Estate" },
  { value: "education", label: "Education" },
  { value: "aviation", label: "Aviation" },
  { value: "retail", label: "Retail & eCommerce" },
];

const config = {
  headers: { Authorization: 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN}
};

let gtmId = null;
let site_code = null;

try {
  await axios.get(
    process.env.REACT_APP_BASE_URL + 'analytics/',
    config 
  ).then((response) => {
    gtmId = response.data.google_tag_manager;
    site_code = response.data.site_code;
  });
} catch (error) {
  console.error("Error getting analytics code:", error);
}

const tagManagerArgs = {
  gtmId: gtmId
}
TagManager.initialize(tagManagerArgs);

function App() {

  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [capVal, setCapVal] = useState();
  const captchaRef = useRef(null);
  const [defaultCountry, setDefaultCountry] = useState('');

  useEffect(() => {
    const fetchCountryCode = async () => {
      const countryCode = await getCountryCode();
      setDefaultCountry(countryCode);
    };

    fetchCountryCode();
  }, []);


  useEffect(() => {
    const existingMetaTag = document.querySelector('meta[name="google-site-verification"]');
    if (!existingMetaTag) {
      // Add the meta tag to the head of the document
      const metaTag = document.createElement('meta');
      metaTag.name = 'google-site-verification';
      metaTag.content = site_code;
      document.head.appendChild(metaTag);
    }
  }, []);

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };
 
  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
    setClose(true);
  };

  const handleCloseThankYou = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
    setFormSubmitted(false);
  };

  const handleScroll = () => {
    const navbar = document.getElementById("navbar_main");
    const logo = document.getElementById("navbar-top");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        navbar.classList.add("scrolled");
        logo.classList.add("navbar-black");
      } else {
        navbar.classList.remove("scrolled");
        logo.classList.remove("navbar-black");
      }
    });
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToEnd = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
    if (bottom) {
      setOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollToEnd, {
      passive: true
    });
    return () => {
      window.removeEventListener('scroll', handleScrollToEnd);
    };
  }, [])
  
  const [usrName, setUsrName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [industry, setIndustry] = useState('');
  const [requirement, setRequirement] = useState('');
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const data = {
      name: usrName,
      phone: phone,
      email: email,
      industry: industry,
      requirement: requirement 
    };

    const newErrors = validateForm(data);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        await axios.post(
          process.env.REACT_APP_BASE_URL + 'contact/',
          data,
          config 
        );
        setFormSubmitted(true);
        setClose(true);
        setUsrName('');
        setPhone('');
        setEmail('');
        setIndustry('');
        setRequirement('');
        setCapVal();
        captchaRef.current.reset();
      } catch (error) {
        console.error("Error submitting form:", error);
        setErrors({form: "Error submitting form. Please try again later."}); 
      }
    } else {
      console.log(`Form submission failed due to validation errors.`);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
    }

    if(!isPhoneValid(phone)) {
      errors.phone = "Phone number is invalid";
    }
    return errors;
  };

  return (
    <>
      <Header />
      <Summary defaultCountry={defaultCountry}/>
      <AboutUs />
      <Process />
      <Banner />
      <Services />
      <WeServe />
      <LookingFor />
      <Procure />
      <ChooseUs />
      <Blog />
      <Testimonials />
      <StartProject />
      <Footer />

      {open && !close &&(<Modal isOpen={open} onClose={handleClose}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="popup-close-wrap">
              <button type="button" className="close" data-dismiss="modal" onClick={handleClose}><img src={closeButton} alt="close" /></button>
            </div>
            <div className="modal-body p-0">
              <div className="row">
                <div className="col-lg-5 popup-bg-purple">
                  <div className="contact-us-section">
                    <div className="contact-us-wrap">
                      <h1 className="contactus-poput-title font-900 font-white">Want to Start Projects? We
                        Deliver Odoo ERP Solutions in 4 Weeks
                      </h1>
                    </div>
                    <div className="contact-details-wrap font-white">
                      <h5 className="mb-3 font-700">Contact us</h5>
                      <div className="details-wrap d-flex align-items-center mb-2">
                        <img src={website} alt="website"/>
                        <p className="mb-1">www.codetrade.io</p>
                      </div>
                      <div className="details-wrap d-flex align-items-center mb-2">
                        <img src={emailImage} alt="email"/>
                        <p className="mb-1">info@codetrade.io</p>
                      </div>
                      <div className="details-wrap d-flex align-items-center">
                        <img src={call} alt="call" />
                        <p className="mb-1">+91 94286 13980</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12">
                  <div className="pull-right-enquiry-form form-padding">
                    <h5 className="form-title font-purple font-600">Enquire Now</h5>
                    <form method="POST" id="form" className="enquire-form-wrap needs-validation" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 pl-0">
                          <label for="fname" className="w-full font-label-small mb-2 black-600 pt-3">First
                            Name <span className="astrisk">*</span></label>
                          <img src={nameImage} alt="nameImage" className="input-icon"></img>
                          <input type="text" name="name" id="first_name" placeholder="Enter Name" required
                            className="w-full py-2 placeholder-gray-300 border-2 border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300" value={usrName}
                            onChange={(e) => setUsrName(e.target.value)}/>
                          <div className="empty-feedback invalid-feedback text-red-400 text-sm mt-1">
                            Please provide your Name.
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 pl-0">
                          <label for="phone" className="w-full font-label-small mb-2 black-600 pt-3">Phone
                            Number <span className="astrisk">*</span>
                          </label>
                          <PhoneInput defaultCountry={defaultCountry} value={phone} onChange={(phone) => setPhone(phone)} name="phone" required/>
                          {errors.phone &&
                            <div className="error text-red-400 text-sm mt-1">
                              Please provide a valid phone number.
                            </div>
                          }
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 pl-0">
                          <label for="email" className="w-full font-label-small mb-2 black-600 pt-3">Email
                            Address <span className="astrisk">*</span></label>
                          <img src={emailImg} alt="emailImg" className="input-icon"></img>
                          <input type="email" name="email" id="email" placeholder="Enter Email" required
                            className="w-full py-2 placeholder-gray-300 border-2 border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300" value={email}
                            onChange={(e) => setEmail(e.target.value)}/>
                          {errors.email &&
                            <div className="error text-red-400 text-sm mt-1">
                              Please provide a valid email address.
                            </div>
                          }
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 pl-0 industry-select-dropdown">
                          <label for="industry" className="w-full font-label-small mb-2 black-600 pt-3">
                            Select Industry <span className="astrisk">*</span>
                          </label>
                          <img src={realEstateImg} alt="realEstateImg" className="input-icon real-estate"></img> 
                          <select name="industry" value={industry} onChange={(e) => setIndustry(e.target.value)} className="w-full py-2 placeholder-gray-300 border-2 border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300" placeholder="Default Value" required style={{backgroundColor:"white"}}>
                            <option value='' disabled>Select Industry</option>
                            {options.map((option) => (
                              <option value={option.value}>{option.label}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 pl-0">
                          <label htmlFor="message" className="w-full font-label-small mb-2 black-600 pt-3">
                          Requirement <span className="astrisk">*</span></label>
                          <img src={notes} alt="notes" className="input-icon"></img>
                          <textarea rows="5" name="requirement" id="message" placeholder="Enter Requirement"
                            className="w-full py-2 placeholder-gray-300 border-2 border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            required value={requirement}
                            onChange={(e) => setRequirement(e.target.value)}></textarea>
                          <div className="empty-feedback invalid-feedback text-red-400 text-sm mt-1">
                            Please enter your message.
                          </div>
                        </div>
                        <div className="col-12 p-0 pt-3">
                          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={(val) => setCapVal(val)} ref={captchaRef} className="recaptcha-input"/>
                        </div>
                        <div className="col-12 p-0">
                          <button type="submit" id="submit-btn" className="btn w-full px-3 py-2 mt-3 text-white btn-send" disabled={!capVal}>
                            Send
                          </button>
                        </div>
                      </div>
                      {errors.form &&
                        <div className="error text-red-400 text-sm mt-1">
                          Error submitting form. Please try again later.
                        </div>
                      }
                      <p className="text-base text-center text-gray-400" id="result"></p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>)}

      {formSubmitted && (<Modal isOpen={open} onClose={handleCloseThankYou}>
        <div id="thankyouModal" className='odoo-saudi-codetrade'>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="popup-close-wrap">
                  <button type="button" className="close" data-dismiss="modal" onClick={handleCloseThankYou}><img src={closeButton} alt='' /></button>
              </div>
              <div className="modal-body">
                <div className="thanku-modal-wrap">
                  <img src={doneIcon} alt="" className="thanku-modal-icon" />
                  <h3 className="thanku-modal-title font-purple font-600">Thank you for reaching out!<br />
                    We will get back to you shortly.</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>)}

    </>
  );
}

export default App;
