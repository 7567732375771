import React from "react";
import odoo_development_company_2 from "../assets/images/Odoo-Development-Company-2.svg";
import odoo_development_company_10 from "../assets/images/Odoo-Development-Company-10.svg";

const Banner = () => {
  return (
    <section className="odoo-solutions-banner-section">
      <div className="odoo-solutions-banner-wrapper">
        <img src={odoo_development_company_2} alt="Odoo ERP Implementation Company" className="w-100 desktop-view" />
        <img src={odoo_development_company_10} alt="Odoo ERP Implementation Company" className="w-100 mobile-view" />
      </div>
    </section>
  );
};

export default Banner;