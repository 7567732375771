import React from "react";
import vector from "../assets/images/Vector.png";
import title from "../assets/images/title.svg";
import health_care from "../assets/images/Health Care.svg";
import health_care_white from "../assets/images/Health Care white.svg";
import manufacturing from "../assets/images/manufacturing.svg";
import manufacturing_white from "../assets/images/manufacturing white.svg";
import education from "../assets/images/education.svg";
import education_white from "../assets/images/education white.svg";
import real_estate from "../assets/images/real estate.svg";
import real_estate_white from "../assets/images/real estate white.svg";
import aviation from "../assets/images/aviation.svg";
import aviation_white from "../assets/images/aviation white.svg";
import retail from "../assets/images/Retail & E-Commerce.svg";
import retail_white from "../assets/images/Retail & E-Commerce white.svg";


const WeServe = () => {
  return (
    <section className="whom-we-serve-section position-relative">
      <div className="whom-we-serve-section-bg-arrow">
        <img src={vector} alt="vector" className="arrow-img" />
      </div>
      <div className="container section-spacing">
        <div className="section-title-wrap">
          <h2 className="section-title font-purple font-900 position-relative width-fit mx-auto">
            TO WHOM WE SERVE ?
            <img src={title} alt="title" className="title-highlight" />
          </h2>
        </div>
        <p className="desc-wrap-text text-center pt-4 mb-0">
          CodeTrade empowers businesses of all sizes with best-in-class Odoo development services. Our team of Odoo experts crafts customized solutions tailored to your specific industry and needs. We don't just implement Odoo, we transform it into a powerful tool that drives growth and optimizes your operations.
        </p>
        <div className="whom-we-serve-cards">
          <div className="category-cards">
            <div className="category-cards-wrap">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-5 d-flex">
                  <a className="industry-card" href="/#">
                    <div className="industry-card-flex card-front">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={health_care} alt={health_care} className="icon-purple"/>
                          </div>
                        </div>
                        <h6 className="card-title font-purple font-600 mb-0">Healthcare</h6>
                        <p className="card-desc mb-0">Our customized Odoo solutions help to streamline administrative procedures, improve patient management, and ensure regulatory compliance with regulatory standards for an improved healthcare experience.</p>
                      </div>
                    </div>
                    <div className="industry-card-flex card-back healthcare-img">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={health_care_white} alt="health_care_white" className="icon-white"/>
                          </div>
                        </div>
                        <h6 className="card-title font-white font-600 mb-0">Healthcare</h6>
                        <p className="card-desc mb-0">Our customized Odoo solutions help to streamline administrative procedures, improve patient management, and ensure regulatory compliance with regulatory standards for an improved healthcare experience.</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-5 d-flex">
                  <a className="industry-card" href="/#">
                    <div className="industry-card-flex card-front">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={manufacturing} alt="manufacturing" className="icon-purple" />
                          </div>
                        </div>
                        <h6 className="card-title font-purple font-600 mb-0">Manufacturing</h6>
                        <p className="card-desc mb-0">We provide Odoo implementation and development services to streamline manufacturing operations, inventory management, and supply chains, help to reduce costs, and improve visibility.</p>
                      </div>
                    </div>
                    <div className="industry-card-flex card-back manufacturing-img">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={manufacturing_white} alt="manufacturing_white" className="icon-white" />
                          </div>
                        </div>
                        <h6 className="card-title font-white font-600 mb-0">Manufacturing</h6>
                        <p className="card-desc mb-0">We provide Odoo implementation and development services to streamline manufacturing operations, inventory management, and supply chains, help to reduce costs, and improve visibility.</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-5 d-flex">
                  <a className="industry-card" href="/#">
                    <div className="industry-card-flex card-front">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={real_estate} alt="real_estate" className="icon-purple" />
                          </div>
                        </div>
                        <h6 className="card-title font-purple font-600 mb-0">Real Estate</h6>
                        <p className="card-desc mb-0">With our custom Odoo solutions, CodeTrade empowers real estate businesses to achieve peak efficiency. We craft powerful tools for managing listings, leads, and client relationships.</p>
                      </div>
                    </div>
                    <div className="industry-card-flex card-back realestate-img">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={real_estate_white} alt="real_estate_white" className="icon-white" />
                          </div>
                        </div>
                        <h6 className="card-title font-white font-600 mb-0">Real Estate</h6>
                        <p className="card-desc mb-0">With our custom Odoo solutions, CodeTrade empowers real estate businesses to achieve peak efficiency. We craft powerful tools for managing listings, leads, and client relationships.</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-5 d-flex">
                  <a className="industry-card" href="/#">
                    <div className="industry-card-flex card-front">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={education} alt="education" className="icon-purple" />
                          </div>
                        </div>
                        <h6 className="card-title font-purple font-600 mb-0">Education</h6>
                        <p className="card-desc mb-0">We offer customized Odoo solutions for educational institutions to streamline administration and enhance learning experiences. It helps to fulfill the unique needs of schools, universities, and training institutes.</p>
                      </div>
                    </div>
                    <div className="industry-card-flex card-back education-img">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={education_white} alt="education_white" className="icon-white" />
                          </div>
                        </div>
                        <h6 className="card-title font-white font-600 mb-0">Education</h6>
                        <p className="card-desc mb-0">We offer customized Odoo solutions for educational institutions to streamline administration and enhance learning experiences. It helps to fulfill the unique needs of schools, universities, and training institutes.</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-5 d-flex">
                  <a className="industry-card" href="/#">
                    <div className="industry-card-flex card-front">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={aviation} alt="aviation" className="icon-purple" />
                          </div>
                        </div>
                        <h6 className="card-title font-purple font-600 mb-0">Aviation</h6>
                        <p className="card-desc mb-0">We streamline fleet management, maintain schedules, and ensure compliance with integrated <a href="https://www.codetrade.io/erp-crm/" target="_blank" rel="noopener noreferrer" className="font-purple">Odoo ERP solutions</a> to enhance safety and efficiency for airlines and MRO service providers.</p>
                      </div>
                    </div>
                    <div className="industry-card-flex card-back aviation-img">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={aviation_white} alt="aviation_white" className="icon-white" />
                          </div>
                        </div>
                        <h6 className="card-title font-white font-600 mb-0">Aviation</h6>
                        <p className="card-desc mb-0">We streamline fleet management, maintain schedules, and ensure compliance with integrated <a href="https://www.codetrade.io/erp-crm/" target="_blank" rel="noopener noreferrer" className="font-purple">Odoo ERP solutions</a> to enhance safety and efficiency for airlines and MRO service providers.</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-5 d-flex">
                  <a className="industry-card" href="/#">
                    <div className="industry-card-flex card-front">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={retail} alt="retail" className="icon-purple" />
                          </div>
                        </div>
                        <h6 className="card-title font-purple font-600 mb-0">Retail & E-Commerce</h6>
                        <p className="card-desc mb-0">We enhance eCommerce by integrating Odoo inventory management, point-of-sale systems, and online storefronts. It manages multi-channel sales, automates order processing, & analyzes customer behavior.</p>
                      </div>
                    </div>
                    <div className="industry-card-flex card-back retail-img">
                      <div className="industry-card-wrap">
                        <div className="card-top">
                          <div className="card-icon-bg">
                            <img src={retail_white} alt="retail_white" className="icon-white" />
                          </div>
                        </div>
                        <h6 className="card-title font-white font-600 mb-0">Retail & E-Commerce</h6>
                        <p className="card-desc mb-0">We enhance eCommerce by integrating Odoo inventory management, point-of-sale systems, and online storefronts. It manages multi-channel sales, automates order processing, & analyzes customer behavior.</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeServe;