import React from "react";
import OwlCarousel from 'react-owl-carousel';
import title from "../assets/images/title.svg";
import rating from "../assets/images/rating.svg";

const options = {
  items: 3,
  margin: 10,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: [
    '<span class="fas fa-chevron-left fa-1x"></span>',
    '<span class="fas fa-chevron-right fa-1x"></span>',
  ],
  responsive: {
    0: {
      items: 1,
    },
    552: {
      items: 1,
    },
    1024: {
      items: 3,
    },
    1366: {
      items: 3,
    },
  },
};

const Testimonials = () => {
  return (
    <section className="clients-testimonial-section position-relative">
      <div className="container section-spacing">
        <div className="section-title-wrap">
          <h2 className="section-title font-purple font-900 position-relative width-fit mx-auto">
            WHAT OUR CLIENTS SAY <br />ABOUT US
            <img src={title} alt="title" className="title-highlight-testimonial" />
          </h2>
        </div>
        <OwlCarousel items={3} className="testimonial-cards owl-carousel owl-theme" loop nav margin={8} center {...options}>
          <div className="testimonial-card">
            <div className="testimonial-card-top">
              <img src={rating} alt="rating" className="testimonial-rating mx-auto" />
            </div>
            <div className="testimonial-card-middle">
              <p className="testimonial-desc mb-0">
                We struggled with a cluttered inventory management system, after implementing Odoo ERP into my business it completely transformed our operations and provided real-time visibility into stack level and order fulfillment. As a result, we've seen a significant increase in efficiency and customer satisfaction.
              </p>
            </div>
            <div className="testimonial-card-bottom">
              <h5 className="testimonial-client-name font-purple font-700">
                Talya Deane
              </h5>
            </div>
          </div>
          <div className="testimonial-card">
            <div className="testimonial-card-top">
              <img src={rating} alt="rating" className="testimonial-rating mx-auto" />
            </div>
            <div className="testimonial-card-middle">
              <p className="testimonial-desc mb-0">
                As a top construction company in Saudi Arabia, we need custom modules to track project progress and manage resources. CodeTrade provides excellent Odoo development and implementation services, impressing us with their professionalism and expertise.
              </p>
            </div>
            <div className="testimonial-card-bottom">
              <h5 className="testimonial-client-name font-purple font-700">
                Khaled Rokban
              </h5>
            </div>
          </div>
          <div className="testimonial-card">
            <div className="testimonial-card-top">
              <img src={rating} alt="rating" className="testimonial-rating mx-auto" />
            </div>
            <div className="testimonial-card-middle">
              <p className="testimonial-desc mb-0">
                We migrated from a legacy system to a new Odoo ERP system designed for the healthcare industry with the help of CodeTrade Odoo experts. The new system has improved our inventory management, order fulfillment, and financial reporting.
              </p>
            </div>
            <div className="testimonial-card-bottom">
              <h5 className="testimonial-client-name font-purple font-700">
                Jay Martin
              </h5>
            </div>
          </div>
          <div className="testimonial-card">
            <div className="testimonial-card-top">
              <img src={rating} alt="rating" className="testimonial-rating mx-auto" />
            </div>
            <div className="testimonial-card-middle">
              <p className="testimonial-desc mb-0">
                Our previous accounting software couldn't handle our complex financial operations. The Odoo development team at CodeTrade customized the platform to meet our specific accounting needs and it improves financial reporting, automates workflows, and better control over our cash flow.
              </p>
            </div>
            <div className="testimonial-card-bottom">
              <h5 className="testimonial-client-name font-purple font-700">
                Emmanuel Rodriguez
              </h5>
            </div>
          </div>
          <div className="testimonial-card">
            <div className="testimonial-card-top">
              <img src={rating} alt="rating" className="testimonial-rating mx-auto" />
            </div>
            <div className="testimonial-card-middle">
              <p className="testimonial-desc mb-0">
                As a growing online jewelry business, we needed a strong and scalable ERP system to manage our orders, customers, and inventory. The Odoo development services provided by CodeTrade were an ideal solution. Since implementing Odoo, we have observed a substantial increase in customer satisfaction and order processing speed.
              </p>
            </div>
            <div className="testimonial-card-bottom">
              <h5 className="testimonial-client-name font-purple font-700">
                Alex Partovi
              </h5>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </section>
  );
};

export default Testimonials;