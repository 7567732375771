import React from "react";
import fb from "../assets/images/fb.svg";
import twitter from "../assets/images/twitter.svg";
import linkedin from "../assets/images/linkedin.svg";
import insta from "../assets/images/insta.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import flag from "../assets/images/uae-flag.svg";
import map from "../assets/images/map.svg";

const Footer = () => {
  return (
    <section className="footer-copyright-section">
      <div className="footer-copyright-wrapper">
        <div className="container">
          <div className="social-media-icons">
            <div className="icon-wrap">
              <a href="https://www.facebook.com/codetradeindiapvtltd" target="_blank" rel="noopener noreferrer">
                <img src={fb} alt="fb" />
              </a>
            </div>
            <div className="icon-wrap">
              <a href="https://www.instagram.com/codetrade.io/" target="_blank" rel="noopener noreferrer">
                <img src={insta} alt="insta" />
              </a>
            </div>
            <div className="icon-wrap">
              <a href="https://twitter.com/Codetrade_India" target="_blank" rel="noopener noreferrer">
                <img src={twitter} alt="twitter" />
              </a>
            </div>
            <div className="icon-wrap">
              <a href="https://www.linkedin.com/company/codetrade-india-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="linkedin" />
              </a>
            </div>
          </div>
          <div className="footer-page-links">
            <div className="page-link-wrap">
              <div className="link-wrap">
                <a href="https://www.codetrade.io/privacy-policy-2/" target="_blank" rel="noopener noreferrer" className="font-white font-600">Privacy Policy</a>
              </div>
              <div className="link-break"></div>
                <div className="link-wrap">
                <a href="https://www.codetrade.io/terms-and-conditions/" target="_blank" rel="noopener noreferrer" className="font-white font-600">Disclaimer</a>
              </div>
              <div className="link-break"></div>
              <div className="link-wrap">
                <p className="font-white font-600 mb-0 pb-2">Copyright © 2024 CodeTrade.io</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="odoo-theme-custom-footer">
        <div className="container">
          <div className="custom-footer-contact-list">
            <div className="contact-list-item">
                <img src={whatsapp} alt="whatsapp" className="item-icon" />
                <a href="https://web.whatsapp.com/send?phone=919428613980" rel="noopener noreferrer" target="_blank">+919428613980</a>
            </div>
            <div className="contact-list-item">
                <img src={flag} alt="India" className="item-icon" />
                <a href="tel:+919428613980" rel="noopener noreferrer" target="_blank">+971559858572</a>
            </div>
            <div className="contact-list-item">
                <span>Email :</span>
                <a href="mailto:info@codetrade.io" rel="noopener noreferrer" target="_blank">info@codetrade.io</a>
            </div>
            <div className="contact-list-item">
                <img src={map} alt="India" className="item-icon" />
                <a href="mailto:info@codetrade.io" rel="noopener noreferrer" target="_blank">Platinum Business Centre- 7 Baghdad Street - Al Nahda - Dubai - United Arab Emirates</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;