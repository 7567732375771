import React, {useState, useRef} from "react";
import axios from "axios";
import vector from "../assets/images/Vector.png";
import name from "../assets/images/NAME.svg";
import emailImage from "../assets/images/email.svg";
import notes from "../assets/images/note.svg";
import closeButton from "../assets/images/close.svg";
import doneIcon from "../assets/images/done-icon.svg";
import Modal from './Modal';
import { PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import 'react-international-phone/style.css';
import ReCAPTCHA from 'react-google-recaptcha';

const Summary = ({defaultCountry}) => {

  const [errors, setErrors] = useState({});
  const [usrName, setUsrName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [requirement, setRequirement] = useState('');
  const [capVal, setCapVal] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const captchaRef = useRef(null);

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const handleCloseThankYou = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setFormSubmitted(false);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: usrName,
      phone: phone,
      email: email,
      requirement: requirement 
    };

    const config = {
      headers: { Authorization: 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN}
    };

    const newErrors = validateForm(data);
    setErrors(newErrors);
 
    if (Object.keys(newErrors).length === 0) {
      try {
        await axios.post(
          process.env.REACT_APP_BASE_URL + 'contact/',
          data,
          config 
        );
        setFormSubmitted(true);
        setUsrName('');
        setPhone('');
        setEmail('');
        setRequirement('');
        setCapVal();
        captchaRef.current.reset();
      } catch (error) {
        console.error("Error submitting form:", error);
        setErrors({form: "Error submitting form. Please try again later."});
      }
    } else {
      console.log('Form submission failed due to validation errors.');
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
    }

    if(!isPhoneValid(phone)) {
      errors.phone = "invalid phone number";
    }

    return errors;
  };

  return (
    <section className="odoo-theme-main-bg position-relative">
      <div className="theme-section-bg-arrow">
        <img src={vector} alt="vector" className="arrow-img" />
      </div>
      <div className="theme-sectionbg-wrap">
        <div className="container">
          <div className="section-inner-wrap">
            <div className="row align-items-end">
              <div className="col-lg-7 col-md-6 col-12">
                <div className="pull-left-content font-white">
                  <h1 className="sectionbg-main-title">
                    GROW YOUR BUSINESS WITH ODOO ERP DEVELOPMENT COMPANY
                  </h1>
                  <p className="scetiongb-content pt-3">
                    CodeTrade, a trusted Odoo partner in Saudi Arabia, offers a top-notch solution. With the specialization of an Odoo Development Company assure and deliver high-quality service with customer satisfaction. Our team of expert Odoo developers crafts customized ERP solutions to suit your unique business needs perfectly. Get ready to transform your business with a powerful Odoo ERP system tailored specifically for you.
                  </p>
                  <a href="https://www.codetrade.io/request-a-quote/" target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-get-started mt-3">Get Started</button>
                  </a>
                  <div className="sectionbg-num-infographics pt-5">
                    <div className="infographic-wrap">
                      <div className="infographic-col">
                        <h3 className="infographic-num">100 +</h3>
                        <p className="infographic-desc">Total Projects</p>
                      </div>
                      <div className="infographic-col">
                        <h3 className="infographic-num">50 +</h3>
                        <p className="infographic-desc">Client World Wide</p>
                      </div>
                      <div className="infographic-col">
                        <h3 className="infographic-num">10 +</h3>
                        <p className="infographic-desc">Years of Experience</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-12">
                <div className="pull-right-enquiry-form form-padding">
                  <h5 className="form-title font-purple font-600">Enquire Now</h5>
                  <form method="POST" id="form" className="enquire-form-wrap needs-validation" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12 pt-3">
                        <label htmlFor="fname" className="w-full font-label-small mb-2 black-600">
                          Name <span className="astrisk">*</span>
                        </label>
                        <img src={name} alt="name" className="contact-input-icon"></img>
                        <div className="input-form-wrap">
                          <span className="input-icon"></span>
                          <input
                            type="text"
                            name="name"
                            id="first_name"
                            placeholder="Enter Name"
                            value={usrName}
                            onChange={(e) => setUsrName(e.target.value)}
                            required
                            className="w-full py-2 placeholder-gray-300 border-2 border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                          />
                        </div>
                        <div className="empty-feedback invalid-feedback text-red-400 text-sm mt-1">
                          Please provide your first name.
                        </div>
                      </div>
                      <div className="col-12">
                        <label htmlFor="phone" className="w-full font-label-small mb-2 black-600 pt-3">
                          Phone Number <span className="astrisk">*</span>
                        </label>
                        {defaultCountry && (<PhoneInput defaultCountry={defaultCountry} value={phone} onChange={(phone) => setPhone(phone)} name="phone" required/>)}
                        {errors.phone &&
                          <div className="error text-red-400 text-sm mt-1">
                            Please provide a valid phone number. 
                          </div>
                        }
                      </div>
                      <div className="col-12">
                        <label htmlFor="email" className="w-full font-label-small mb-2 black-600 pt-3">
                          Email Address <span className="astrisk">*</span>
                        </label>
                        <img src={emailImage} alt="emailImage" className="contact-input-icon"></img>
                        <input
                          name="email"
                          id="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          className="w-full py-2 placeholder-gray-300 border-2 border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                        />
                        {errors.email &&
                          <div className="error text-red-400 text-sm mt-1">
                            Please provide a valid email address.
                          </div>
                        }
                      </div>
                      <div className="col-12">
                        <label htmlFor="requirement" className="w-full font-label-small mb-2 black-600 pt-3">
                          Requirement <span className="astrisk">*</span>
                        </label>
                        <img src={notes} alt="notes" className="contact-input-icon"></img>
                        <textarea
                          rows="5"
                          name="requirement"
                          id="requirement"
                          placeholder="Enter Requirement"
                          value={requirement}
                          onChange={(e) => setRequirement(e.target.value)}
                          className="w-full py-2 placeholder-gray-300 border-2 border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                          required>
                        </textarea>
                        <div className="empty-feedback invalid-feedback text-red-400 text-sm mt-1">
                          Please enter your message.
                        </div>
                      </div>
                      <div className="col-12 pt-3">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={(val) => setCapVal(val)} ref={captchaRef} className="recaptcha-input"/>
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn w-full px-3 py-2 mt-3 text-white btn-send" disabled={!capVal}>
                          Send
                        </button>
                      </div>
                    </div>
                    {errors.form &&
                      <div className="error text-red-400 text-sm mt-1">
                        Error submitting form. Please try again later.
                      </div>
                    }
                    <p className="text-base text-center text-gray-400" id="result"></p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {formSubmitted && (<Modal onClose={handleCloseThankYou}>
        <div id="thankyouModal" className="odoo-saudi-codetrade">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="popup-close-wrap">
                <button type="button" className="close" data-dismiss="modal" onClick={handleCloseThankYou}><img src={closeButton} alt='' /></button>
              </div>
              <div className="modal-body">
                <div className="thanku-modal-wrap">
                  <img src={doneIcon} alt="" className="thanku-modal-icon" />
                  <h3 className="thanku-modal-title font-purple font-600">Thank you for reaching out!<br />
                    We will get back to you shortly.</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>)}
  </section>
  );
};

export default Summary;