import React from "react";
import odoo_logo_rgb from "../assets/images/Odoo_logo_rgb (1).png";
import vector from "../assets/images/Vector.png";
import integration from "../assets/images/Odoo ERP Integration.png";
import developers from "../assets/images/skilled_developers.png";
import solution from "../assets/images/AI-DRIVEN SOLUTIONS.png";
import ecommerce from "../assets/images/ecommerce.png";

const Procure = () => {
  return (
    <section className="procuring-moment-for-section position-relative">
      <div className="procuring-moment-wrapper">
        <div className="container section-spacing">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="section-title-wrap mb-5">
                <h2 className="section-title2 font-purple font-900">
                  OUR ERP EXPERTISE
                </h2>
              </div>
              <div className="procuring-card-main produring-crd-one">
                <div className="procuring-card-wrapper">
                  <div className="procuring-card">
                    <div className="procuring-card-wrap">
                      <div className="pt-3">
                        <div className="" style={{textAlign: "right"}}>
                          <img src={odoo_logo_rgb} alt="odoo_logo_rgb" style={{width: "50%", marginTop: "10px"}} />
                        </div>
                      </div>
                      <div className="">
                        <div className="procuring-card-top pt-2">
                          <div className="procuring-card-bg">
                            <img src={integration} alt="integration" className="service-icon" />
                          </div>
                        </div>
                      </div>
                      <h5 className="procuring-card-title font-purple font-600 my-3">
                        Odoo ERP Integration
                      </h5>
                    </div>
                  </div>
                  <div className="card-blank-height"></div>
                  <p className="procuring-card-desc">
                    Optimize your operations and enhance productivity with our Odoo ERP integration services. It enables seamless coordination across all platforms.
                  </p>
                </div>
              </div>
              <div className="procuring-card-main produring-crd-two">
                <div className="procuring-card-wrapper">
                  <div className="procuring-card ml-auto">
                    <div className="procuring-card-wrap">
                      <div className="pt-3">
                        <div className="" style={{textAlign: "right"}}>
                          <img src={odoo_logo_rgb} alt="odoo_logo_rgb" style={{width: "50%", marginTop: "10px"}} />
                        </div>
                      </div>
                      <div className="">
                        <div className="procuring-card-top pt-2">
                          <div className="procuring-card-bg">
                            <img src={solution} alt="solution" className="service-icon" />
                          </div>
                        </div>
                      </div>
                      <h5 className="procuring-card-title font-purple font-600 my-3">
                        AI-Driven Odoo Solutions
                      </h5>
                    </div>
                  </div>
                  <div className="card-blank-height"></div>
                  <p className="procuring-card-desc">
                    We enhance your Odoo ERP system with AI, optimizing operations and providing outstanding customer experiences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="procuring-card-main produring-crd-thr">
                <div className="procuring-card-wrapper">
                  <div className="procuring-card ml-auto">
                    <div className="procuring-card-wrap">
                      <div className="pt-3">
                        <div className="" style={{textAlign: "right"}}>
                            <img src={odoo_logo_rgb} alt="odoo_logo_rgb" style={{width: "50%", marginTop: "10px"}} />
                        </div>
                        </div>
                        <div className="">
                          <div className="procuring-card-top pt-2">
                            <div className="procuring-card-bg">
                              <img src={developers} alt="developers" className="service-icon" />
                            </div>
                          </div>
                        </div>
                        <h5 className="procuring-card-title font-purple font-600 my-3">
                          Hire Odoo Developers
                        </h5>
                      </div>
                    </div>
                    <div className="card-blank-height"></div>
                    <p className="procuring-card-desc">
                      We have highly skilled Odoo developers who deliver top-notch Odoo development and implementation services and work based on your time zone.
                    </p>
                </div>
              </div>
              <div className="procuring-card-main produring-crd-four">
                <div className="procuring-card-wrapper">
                  <div className="procuring-card">
                    <div className="procuring-card-wrap">
                      <div className="pt-3">
                        <div className="" style={{textAlign: "right"}}>
                          <img src={odoo_logo_rgb} alt="odoo_logo_rgb" style={{width: "50%", marginTop: "10px"}} />
                        </div>
                      </div>
                      <div className="">
                        <div className="procuring-card-top pt-2">
                          <div className="procuring-card-bg">
                            <img src={ecommerce} alt="ecommerce" className="service-icon" />
                          </div>
                        </div>
                      </div>
                      <h5 className="procuring-card-title font-purple font-600 my-3">
                        eCommerce Solutions
                      </h5>
                    </div>
                  </div>
                  <div className="card-blank-height"></div>
                  <p className="procuring-card-desc">
                    We offer Odoo <a href="https://www.codetrade.io/e-commerce/" target="_blank" className="font-purple" rel="noopener noreferrer">eCommerce solutions</a> to empower online businesses, streamline operations, and fuel eCommerce growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="procuring-section-bg-arrow">
        <img src={vector} alt="vector" className="arrow-img" />
      </div>
    </section>
  );
};

export default Procure;