import React from "react";
import title from "../assets/images/title.svg";
import done_icon from "../assets/images/done-icon.png";

const ChooseUs = () => {
  return (
    <section className="why-choose-us-section">
      <div className="container section-spacing">
        <div className="section-title-wrap">
          <h2 className="section-title font-purple font-900 position-relative width-fit mx-auto">
            WHY CHOOSE US ?
            <img src={title} alt="title" className="title-highlight" />
          </h2>
        </div>
        <div className="about-us-content-wrapper">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-12">
              <div className="about-us-desc-wrap">
                <h3 className="desc-wrap-title font-black font-700 text-align-center text-uppercase">
                  YOUR GUIDE TO SEAMLESS ODOO INTEGRATION
                </h3>
                <p className="desc-wrap-text text-align-center">
                As a leading Odoo ERP development agency and an official Odoo Community Partner, CodeTrade has a strong track record with numerous successful projects. Our team of Odoo developers brings extensive experience in delivering even the most complex projects on time.
                We specialize in Odoo development and implementation solutions that optimize your business processes to meet current needs and future growth. Our customization services are tailored to align with both immediate requirements and long-term objectives.
                </p>
                <ul className="desc-wrap-points-list">
                  <li>
                    <div className="bullet-point-wrap">
                      <div className="bullet-point-bg">
                        <img src={done_icon} alt="done_icon" />
                      </div>
                    </div>
                    <span><a href="https://www.codetrade.io/hire-odoo-developers/" target="_blank" className="font-purple" rel="noopener noreferrer">Hire Odoo developers</a> with over 5+ years of experience and in-depth knowledge which translates to efficient project execution and handling complex Odoo functionalities.</span>
                  </li>
                  <li>
                    <div className="bullet-point-wrap">
                      <div className="bullet-point-bg">
                        <img src={done_icon} alt="done_icon" />
                      </div>
                    </div>
                    We understand budget concerns and strive to deliver affordable Odoo development solutions without compromising quality.
                  </li>
                  <li>
                    <div className="bullet-point-wrap">
                      <div className="bullet-point-bg">
                        <img src={done_icon} alt="done_icon" />
                      </div>
                    </div>
                    We emphasize on-time delivery and use an Agile Methodology, which can help to ensure that your project stays on track.
                  </li>
                  <li>
                    <div className="bullet-point-wrap">
                      <div className="bullet-point-bg">
                        <img src={done_icon} alt="done_icon" />
                      </div>
                    </div>
                    Offer full authority over your project code, which gives you control and helps ensure data security.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-12">
              <div className="about-us-img-wrap">
                <img src={require('../assets/images/Odoo-Development-Company-3.png')} alt="Odoo ERP Implementation Company" className="about-us-img w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;