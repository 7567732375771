import React from "react";
import title from "../assets/images/title.svg";
import odoo_development_company_9 from "../assets/images/Odoo-Development-Company-9.svg";


const Process = () => {
  return (
    <section className="odoo-development-process-section">
      <div className="container section-spacing-bottom">
        <div className="section-title-wrap">
          <h2 className="section-title font-purple font-900 position-relative width-fit mx-auto">
            OUR ODOO DEVELOPMENT PROCESS
            <img src={title} alt="title" className="title-highlight" />
          </h2>
        </div>
        <p className="desc-wrap-text text-center pt-4 mb-0">
        Our Odoo development process is designed to deliver high-quality, customized solutions tailored to your business needs. By leveraging the robust features of Odoo, we streamline operations, enhance productivity, and drive growth. Our process is transparent, collaborative, and customer-centric, ensuring that each project aligns perfectly with your strategic goals.
        </p>
        <div className="odoo-development-process-banner-wrap">
          <img src={require('../assets/images/Odoo-Development-Company-5.png')} className="w-100 desktop-view" alt="Odoo ERP Implementation Company"/>
          <img src={odoo_development_company_9} className="mobile-view development-process-wrap" alt="Odoo ERP Implementation Company"/>
        </div>
      </div>
  </section>
  );
};

export default Process;